import React from 'react';
import Box from '@mui/material/Box'; 

const Petinfo = ({ petData }) => {
  const user=petData?.pet_profiles?.pet_profiles;
  const isUser=user?.length > 0;

  return (
    <>
      {isUser ? 
        <Box className="user-info-body">
          <Box className="user-info-inside">
            <Box className="user-info-inside-head-second">
              <Box className="user-th-head"><h4>Pet Name</h4></Box>
              <Box className="user-th-head"><h4>Pet Id</h4></Box>
              <Box className="user-th-head"><h4>Breed Name</h4></Box>
              <Box className="user-th-head"><h4>Gender</h4></Box>
              <Box className="user-th-head"><h4>Weight</h4></Box>
              <Box className="user-th-head"><h4>Birthday</h4></Box>
            </Box>
            <Box className="">
              {user?.map((value, id) => {
                return <>
                  <Box className="user-info-inside-head-second" key={id}>
                    <Box className="user-th-head"> 
                      <p>{value?.name || "-"}</p>
                    </Box>
                    <Box className="user-th-head">
                      <p> {value?.vetbuddy_patient_id || "-"}</p>
                    </Box>
                    <Box className="user-th-head">
                      <p>{value?.pet_breed || "-"}</p>
                    </Box>
                    <Box className="user-th-head">
                      <p>{value?.gender || "-"}</p>
                    </Box>
                    <Box className="user-th-head">
                      {value?.weight === "" ? <p>-</p> : <p>{(value?.weight)?.toString()?.padStart(2, '0')} Kgs </p>}          
                    </Box>
                    <Box className="user-th-head"><p>{value?.dob?.split("-")?.reverse()?.join('-') || "-"}</p></Box>
                  </Box> 
                </>
              })}
            </Box>
          </Box>
        </Box>:<p className='user-name-info' style={{margin:"1rem 1.5rem"}}>No records found, please ask for pet details.</p>
      }
    </>
  );
};

export default Petinfo;