import Food from './Food';
import axios from 'axios';
import Request from './Request';
import Petinfo from './petInfo';
import Box from '@mui/material/Box'; 
import UserInformation from './user';
import Membership from './Membership';
import Vaccination from './Vaccination';
import { MdExpandMore } from 'react-icons/md';
import { MdExpandLess } from 'react-icons/md';
import {FiPhoneCall} from 'react-icons/fi'
import MembershipUtil from './membershipUtil';
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Usernotfound from '../userList/UserNotFound';
import MedicalRecord from './medicalRecord';
import StockIncompleteForm from './StockIncompleteForm';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


import { doc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { onSnapshot } from "firebase/firestore";


const firebaseConfig = {
  apiKey: 'AIzaSyDBmaf5B8Ii9wiPUXWM6l9fpG1iJAOhePU',
  appId: '1:741842529276:web:40e30914419e967d6ce079',
  messagingSenderId: '741842529276',
  projectId: 'vetic-bcf18',
  authDomain: 'vetic-bcf18.firebaseapp.com',
  storageBucket: 'vetic-bcf18.appspot.com',
  measurementId: 'G-YT559YBW66'
};
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();



const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ButtonsView = styled("div")(() => ({
  position: "fixed",
  bottom: 0,
  zIndex: 100,
}));

// const UserDetails = ({ setFlage, unique_id ,contactDetails ,setSuccess, setCallMessage, setOpen, setErrcallMessage,open}) => {  
const UserDetails = ({ setFlage, unique_id  ,setSuccess, setCallMessage, setOpen, setErrcallMessage,open}) => {  
  const [userinfo, setUserinfo] = useState([	
    true,	
    true,	
    true,	
    true,	
    false,	
    false,	
    false,	
    false,	
    false,	
  ]);	
  const [tabIdx, setTabIdx] = useState(-1);
  const [mydata, setMydata] = useState([]);
  const [error, setError] = useState(false);
  const [uuId, setUuId] = useState(unique_id);
  const [loaderspin, setLoaderspin] = useState(true);
  const [requistList, setRequistList] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [ermdata, setErmdata] = useState([]);
  const [teleButton,setTeleButton]=useState(false)
  const [successCallconnection,setSuccessCallconnection]=useState(false)
  const [callApiFaild,setCallApiFaild]=useState(false)
  // clinic manages number
  const [contactDetails,setContactDetails]=useState(null)

  //food perches visit 
  const Foodvisit=mydata?.food_purchase_details?.food_purchase_detail?.length
  //call out data
  const Callout=mydata?.client_callouts?.callouts      
  //membership status active or not
  const MembershipStatus=mydata?.membership_details?.membership_packages?.length
  // expiry date for membership
  const MembershipExpiry=mydata?.membership_details?.membership_packages[0]?.expiry_date;
  //client id
  const Client_id=mydata?.client_profile?.vetbuddy_client_id
  //food request
  const Client_name=mydata?.client_profile?.name
  const Client_mobile=mydata?.client_profile?.mobiles[0]?.mobile==='-'?mydata?.client_profile?.mobiles[1]?.mobile:mydata?.client_profile?.mobiles[0]?.mobile     
  //fetch uuid
  const { userId } = useParams(); //uuid

  useEffect(() => {
    setUuId(userId);
    if(userId){
      setButtonDisabled(true);
    }   
  }, [userId]);
      
  useEffect(() => {
    const vet_instance = localStorage.getItem('vet_id');
    if(vet_instance === null){
      window?.open('https://clinic.vetic.in/',"_self"); 
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://userprofile.vetic.in/user-detail/?unique_id=${uuId}`);
        setMydata(response?.data?.data)
        setLoaderspin(false)
      } catch (error) {
        console.error(error);
        setError(true)  //for 404 page
      }
    };
    if(uuId){
      fetchData()
    }
  }, [uuId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://userprofile.vetic.in/special-requests/`);  
        setRequistList(response?.data?.data); 
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://org.vetic.in/clinic-org/user-emr/?user_id=${uuId}`); 
        setErmdata(response?.data?.data);          
      } catch (error) {
        console.error(error);
      }
    };
    if(uuId){
      fetchData();
    }
  }, [uuId]);

  const handleChange = (i) => () => {	
    setTabIdx(i);	
    setUserinfo((p) => {	
      p[i] = !p[i];	
      return [...p];	
    });
  }

  useEffect(() => {	
    let tabName = "";	
    // eslint-disable-next-line default-case
    switch (tabIdx) {	
      case 0:	
        tabName = "User Info";	
        break;	
      case 1:	
        tabName = "Vaccination Details";	
        break;	
      case 2:	
        tabName = "Membership";	
        break;	
      case 3:	
        tabName = "Food Visits";	
        break;	
      case 4:	
        tabName = "Membership Utilization Details";	
        break;	
      case 5:	
        tabName = "Special Requests";	
        break;	
      case 6:	
        tabName = "Pet Info";	
        break;	
      case 7:	
        tabName = "Medical Records";	
        break;	
      case 8:	
        tabName = "Stock Incomplete form";	
        break;	
    }	
    const event = {	
      event_type: "CLICK",	
      scr_id: "Profile_Page",	
      subaction: userinfo[tabIdx] ? "menu_expanded" : "menu_collapsed",	
      timestamp: Date.now(),	
      source: "99",	
      member_id: localStorage.getItem("user-email"),	
      user_id: unique_id,	
      tab_name: tabName,	
    };	
    handelEventTrigger(event);	
  }, [userinfo]);

  let timeout;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    setButtonDisabled(true);
    clearTimeout(timeout);
    if(userId){
    } else{
    timeout = setTimeout(() => {
      setButtonDisabled(false);
    }, 250);
   }
  };
  
  const Loader = () => {
    return (
      <div className='loader-body'> 
        <div className="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const data = {
      "event_type": "SCREEN_OPENED",
      "scr_id": "Profile_Page",
      "timestamp":Date.now() ,
      "source":"99",
      "member_id": localStorage.getItem("user-email"),
      "user_id": unique_id
    };
    handelEventTrigger(data);  
  }, []);


  // clinic manages number

  useEffect(() => {

    fetch(`https://static.vetic.in/api/clinic-manager-mobiles/${localStorage.getItem("user-email-address").toLowerCase()}`)
    .then(response => response.json())
    .then(data => {
      setContactDetails(data?.data?.fields)
    })
    .catch(error => console.error(error));

  }, []);


  const handelEventTrigger = (value) => {
    const headers = {
       'Content-Type': 'application/json'
    };
    axios.post("https://webhooks.vetic.in/retail-app/click-logs/", value, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.log('error', error));
  };

  const backClicked = () => {
    const event = {
       "event_type": "CLICK",
       "scr_id": "Profile_Page",
       "subaction": "back_clicked",
       "timestamp": Date.now(),
       "source": "99",
       "member_id": localStorage.getItem("user-email"),
       "user_id": unique_id
    };
    handelEventTrigger(event);
 }; 

 const fetchCallStatus = (bookingid) => {
    console.log("hey")
  try {
    const bookingRef = doc(
      firestore,
      'my_operator',localStorage.getItem("user-email-address"),'call_data',bookingid
    );
    const unsubscribe = onSnapshot(bookingRef, (snapshot) => {
      if (snapshot.exists()) {
        const bookingData = snapshot.data();
        
        console.log(bookingData, "kolkata");
        // Check if the status is "Pending" and update it to "Confirm"
        if (bookingData.status === "success") {
          setOpen(true)
          setSuccess(true)
          setCallMessage(bookingData.text)
          setErrcallMessage(false)
          console.log("success hai")
          unsubscribe()
          setTimeout(() => {
            setOpen(false)
            setSuccess(false)
          }, 6000);
        } else if (bookingData.is_accepted) {
          // Update your UI accordingly
          
          
          console.log("confirm")
        } else if (bookingData.is_failed) {
          console.log("failed")
          
        }else if (bookingData.status === "error"){
          setSuccess(true)
          setOpen(true)
          setCallMessage(bookingData.text)
          setErrcallMessage(true)
          console.log("error hai")
          unsubscribe()
          setTimeout(() => {
            setOpen(false)
            setSuccess(false)
          }, 6000);
        }
      }
    });
    // Return the unsubscribe function to stop listening when necessary
    
  } catch (error) {
    console.log('Error getting booking data:', error);
  }
};
console.log(contactDetails,"contact")

 const handelTelecomunication=(callType)=>{
  const event = {
    "event_type": "CLICK",
    "scr_id": "Profile_Page",
    "subaction": "Call now button click",
    "button_type":callType,
    "timestamp": Date.now(),
    "source": "99",
    "member_id": localStorage.getItem("user-email-address"),
    "user_id":  mydata.unique_id
 };
      handelEventTrigger(event)
     
     setTeleButton(true)
      setTimeout(() => {
        setTeleButton(false)
      }, 4000);
      const url = "https://userprofile.vetic.in/call/";
      const headers = {
        "Content-Type": "application/json",
      };
      const mobiles = []
      if(contactDetails.phone1){
        mobiles.push(contactDetails.phone1)
      }
      if(contactDetails.phone2){
        mobiles.push(contactDetails.phone2)
      }
      const data = {
        "unique_id": mydata.unique_id,
        // clinic manages number
        "mobiles": mobiles,
        // "mobiles": contactDetails,
       "dashboard_user_email":localStorage.getItem("user-email-address"),
       "call_type":callType
      };

      axios.post(url, data, { headers })
        .then(response =>
             {
              

              setSuccessCallconnection(true)
              setOpen(true);
              setTimeout(() => {
                setSuccessCallconnection(false)
              }, 4000);
              setTimeout(() => {
                fetchCallStatus(mydata.unique_id)   
              }, 5000);

             })
        .catch(error =>
          { console.error('Error', error)
          setOpen(true);
          setCallApiFaild(true)
          setTimeout(() => {
            setCallApiFaild(false)
          }, 5000);
          
         });
      }


      const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
      
        setOpen(false);
      };

  return (
    <>
      <Box className="desktop_margin">
        {loaderspin ? (error ? <Usernotfound /> : <Loader /> ) :
          <>
            <Box className="user-info">
              <ol className='user-first-section'>
                {Callout?.length === 0 && <p>No Callouts!</p>}
                {Callout?.map((value, id) => {
                  return <li key={id}>{value}</li>
                })}
              </ol>
            </Box>
            <Box style={{marginBottom: "5rem"}}> 
              <h4 className={`${userinfo[0] ? 'user-info-accordination':'active-accordination'}`} > 
                <span style={{padding: "1rem 0 1rem 1.6rem"}}>User Info 
                  <span> ({Client_id || "-"})</span>
                </span> 
                <span className='call_now_in'>
                  <span className="tele_span">
                 {mydata.is_call_button_visible&&<button disabled={teleButton} className={`${teleButton?"tellcallbutton-in":"tellcallbutton"}`}  onClick={()=>handelTelecomunication("clinic")}><span><img style={{display:"flex"}} src='https://vetic-img.s3.ap-south-1.amazonaws.com/website/admin-tool/callIcon.svg' alt="call" /></span>Client Call</button>}
                 {mydata.is_call_button_visible_teleconsultation&&<button disabled={teleButton} className={`${teleButton?"tellcallbutton-in":"tellcallbutton"}`}  onClick={()=>handelTelecomunication("doctor")}><span><img style={{display:"flex",height:"1.2rem"}} src='https://vetic-img.s3.ap-south-1.amazonaws.com/website/admin-tool/docsupportIcon.svg'  alt="call" /></span>Teleconsultation Call</button>}
                  </span>
                  <span className='f-icon-m' onClick={handleChange(0)}>{userinfo[0] ? <MdExpandLess />:<MdExpandMore />}</span>
                  </span>
                </h4>
              {userinfo[0] && <UserInformation data={mydata?.client_profile} petdata={mydata?.pet_profiles} uniq_id={mydata} />}
              <Box>
                <h4 className={`${userinfo[1] ? 'user-info-accordination':'active-accordination'}`}  onClick={handleChange(1)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Vaccination Details</span> 
                  <span className='f-icon-m'>{userinfo[1] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[1] && <Vaccination data={mydata?.vaccination_history} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[2] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(2)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Membership: 
                    <span> {MembershipStatus > 0 ? "Active":"No"} {MembershipStatus > 0 &&
                      <span>({MembershipExpiry})</span>}
                    </span>
                  </span> 
                  <span className='f-icon-m'>{userinfo[2] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[2] && <Membership data={mydata} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[3] ?'user-info-accordination':'active-accordination'}`} onClick={handleChange(3)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Food Visits: 
                    <span> {(Foodvisit)?.toString()?.padStart(2, '0') || "No visits"}</span>
                  </span> 
                  <span className='f-icon-m'>{userinfo[3] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[3] && <Food data={mydata?.food_purchase_details} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[4] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(4)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Membership Utilization Details</span> 
                  <span className='f-icon-m'>{userinfo[4] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[4] && <MembershipUtil data={mydata} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[5] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(5)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Special Requests</span> 
                  <span className='f-icon-m'>{userinfo[5] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[5] && <Request data={mydata} re_list={requistList} /> }
              </Box>  
              <Box>
                <h4 className={`${userinfo[6] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(6)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Pet Info</span> 
                  <span className='f-icon-m'>{userinfo[6] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[6] && <Petinfo petData={mydata} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[7] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(7)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Medical Records</span> 
                  <span className='f-icon-m'>{userinfo[7] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[7] && <MedicalRecord ermdata={ermdata} /> }
              </Box>
              <Box>
                <h4 className={`${userinfo[8] ? 'user-info-accordination':'active-accordination'}`} onClick={handleChange(8)}> 
                  <span style={{padding: "1rem 0 1rem 1.6rem"}}>Stock Incomplete form</span> 
                  <span className='f-icon-m'>{userinfo[8] ? <MdExpandLess />:<MdExpandMore />}</span>
                </h4>
                {userinfo[8] && <StockIncompleteForm Client_name={Client_name} Client_id={Client_id} Client_mobile={Client_mobile} unique_id={unique_id} /> }
              </Box>
              <ButtonsView>
                <Box className="button-section-back" onClick={() => backClicked()}>
                  {buttonDisabled ? null : <button  onClick={(e)=>setFlage(true)} className='back-shift-button'>Back</button>}
                </Box>
              </ButtonsView>       
            </Box>
            {successCallconnection&&<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Call initiated
          </Alert>
          
        </Snackbar>}

        {callApiFaild&&<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
      Something went wrong. Please contact central team!
      </Alert>
      </Snackbar>}     
         
          </>
        }
      </Box>
    </>
  );
};

export default UserDetails;