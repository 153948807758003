import React from 'react';
import Box from '@mui/material/Box'; 

const Petinfo = ({ ermdata }) => {
    return (
        <Box>
            <Box className="user-info-body">
                <Box className="user-info-inside">
                    <Box className="user-info-inside-head-second-emr">
                        <Box className="user-th-head-emr"><h4>Pet Name</h4></Box>
                        <Box className="user-th-head-emr"><h4>Pet Id</h4></Box>
                        <Box className="user-th-head-emr"><h4>EMR</h4></Box>
                    </Box>
                    <Box className="">
                        {ermdata?.map((value, id) => {
                            return <>
                                <Box className="user-info-inside-head-second-emr" key={id}>
                                    <Box className="user-th-head-emr"> <p>{value.name}</p></Box>
                                    <Box className="emr-area">
                                        {value.emr_data.map((val, id) => {
                                            return <Box key={id} className="emr-list">
                                                <Box className="user-th-head-emr-r"> 
                                                    <p>{val.vetbuddy_patient_id}</p>
                                                </Box>
                                                <Box className="user-th-head-emr-r">
                                                    <p>
                                                        <a style={{textDecoration:"none"}} href={val.prescription_link} target="_blank" rel="noreferrer">{val.file_name}</a>
                                                    </p> 
                                                </Box>
                                            </Box>
                                        })}
                                    </Box>                     
                                </Box>
                            </>
                        })}
                    </Box>   
                </Box>
            </Box>
        </Box>   
    );
};

export default Petinfo;