import React from 'react';
import Box from '@mui/material/Box'; 

const MembershipUtilization = ({ data }) => {  
  const Membership=data?.membership_details?.membership_packages;
  const Membershippackage=data?.membership_details?.membership_packages?.length > 0;

  return (
    <>
      {Membershippackage ?
        <Box className="user-info-member-util">
          <Box className="membership-table-header">
            <Box className="mu-header"><h4>Package</h4></Box>
            <Box className="mu-header"><h4>Date</h4></Box>
            <Box className="mu-header"><h4>Clinic </h4></Box>
          </Box>
          <Box>
            {Membership?.map((value, id) => {
              return <Box key={id}>
                {value?.membership_package?.map((val, id) => {
                  return <Box key={id} className="vaccination-mu-body">
                    <p>{val?.membership_type || "-"}</p>
                    <Box className="memebership-uses">
                      {val?.membership_usage?.map((value, id) => {
                        return <Box key={id}>
                          <Box className="membership-invoice">
                            <p style={{margin:"5px 0"}}>{value?.invoice_date || "-"}</p>
                            <p style={{margin:"5px 0"}}>{value?.clinic?.split(',')[1] || "-"}</p>
                          </Box>
                        </Box>
                      })}
                    </Box> 
                  </Box>
                })}    
              </Box>
            })}
         </Box> 
        </Box> : <p className='user-name-info' style={{margin:"1rem 1.5rem"}}>No Membership records found</p>
      }
    </>
  );
};

export default MembershipUtilization;                                   