import Box from '@mui/material/Box'; 
import React, { useState } from 'react';

const  UserInfo = ({ data, petdata, uniq_id }) => {
   const [flage, setFlage] = useState(true); 
   const numberofpet = petdata?.pet_profiles?.length;

   const  sendWhatsapp = (e) => {
      e.preventDefault();
      const mobileNumber = uniq_id?.unique_id //send uniq id for number mass chase
      setFlage(false);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
         "unique_id": mobileNumber
      });
      var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
      };
      fetch(`${process.env.REACT_APP_WAITNG_LIST_URL}/app-download-whatsapp/`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
   };

   return (
      <>
         <Box className="user-info-box">
            <Box className="user-info-left">
               <h5 className='user-name-info'>{data?.name} 
                  <span>&nbsp;({data?.mobiles[0]?.mobile === '-' ? (data?.mobiles[1]?.mobile):(data?.mobiles[0]?.mobile)})</span>
               </h5>
               <p className='user-name-info'>Total Pets:<span> {numberofpet}</span></p>
               <p className='user-name-info'>NPS: 
                  <span>&nbsp;&nbsp;
                     {data?.feedback?.status?.toLowerCase()==="happy"&&<span>{data?.feedback?.status} <span>&nbsp;&nbsp;😍</span></span>}
                     {data?.feedback?.status?.toLowerCase()==="unhappy"&&<span>{data?.feedback?.status} <span>&nbsp;&nbsp;😡</span></span>}
                     {data?.feedback?.status?.toLowerCase()==="neutral"&&<span>{data?.feedback?.status} <span>&nbsp;&nbsp;😑</span></span>}
                     {data?.feedback?.status==="-"&&<span>No feedback shared</span> }
                  </span>
               </p>
               {data?.feedback?.l1!=='-'&&<p className='para-item'>L1: {data?.feedback?.l1||"-"} </p>}
               {data?.feedback?.l2!=='-'&&<p className='para-item'>L2: {data?.feedback?.l2||"-"} </p>}
            </Box>
            <Box className="user-info-right">
               <h5 className='user-name-info'>Missing info: 
                  <span>&nbsp; {data?.missiong_info === '-' ?
                     <span>Profile Complete</span>
                     : (data?.missiong_info)}
                  </span>
               </h5>
               <p className='user-name-info'>Total Visits: 
                  <span> &nbsp; {data?.no_of_visits || "0"} &nbsp; 
                     <span>
                        {data?.no_of_visits !== '0' &&
                           <span>(Last visit : {data?.last_visit_date?.split("-")?.reverse()?.join('-')})</span>
                        }
                     </span> 
                  </span>  
               </p>
               <p className='user-name-info'>Visits in last 90 Days: 
                  <span> &nbsp; {data?.visits_in_last_90_days || "0"}</span>
               </p>
               <Box className="app-download-request">
                  <Box>
                     <h5 className='user-name-info' style={{marginTop:"0",marginBottom: "4px"}}>App Downloaded</h5>
                     <h5 className='para-item' style={{margin:"0"}}>{data?.app_downloaded?.toUpperCase()==="YES"?"Yes":"No"}</h5>
                  </Box>
                  {data?.app_downloaded?.toLowerCase() === "no" && <p className='para-item-re' onClick={(e) => sendWhatsapp(e, data)} >{flage && <span>Send Request</span>}</p>}
               </Box>
            </Box>
         </Box>
      </>
   );
};

export default UserInfo;