import axios from 'axios';
import Userlist from './Userlist';
import Box from '@mui/material/Box'; 
import { FiLogOut } from 'react-icons/fi';
import UserInfo from '../userinfo/Index';
import { AiFillHome } from 'react-icons/ai';
import Usernotfound from './UserNotFound';
import React, { useState, useEffect } from 'react';
import Popupclinicselection from './ClinicSelection';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Dashboard = ({contactDetails}) => {
  const [flage, setFlage] = useState(true);                      //manupulate the screen
  const [error, setError] = useState(false);                       //show error on global search
  const [clinics, setClinics] = useState([]);                      //it store all clinic list
  const [mydata, setMydata] = useState(null);                     //wating list data fo clinic and global search
  const [userdata, setUserdata] = useState(null);                  //wating list data fo clinic and global search
  const [global_id, setGlobal_id] = useState(null);                //search with global id or name
  const [globaldata, setGlobaldata] = useState(null);                  //wating list data fo clinic and global search
  const [information, setInformation] = useState(true);              //once click the pasent name the data send userinfo screen
  const [globalSearch, setGlobalSearch] = useState('');           //this is onchange value on g input search
  const [vet_clinic_id, setVet_clinic_id] = useState(null);       //clinic id
  const [vet_instance_id, setVet_instance_id] = useState(null);    //vet buddy instance id 
  const [selectedClinic, setSelectedClinic] = useState('');   //when user select a clinic name store here
  const [button_display, setButton_display] = useState('');    //once we select clinic the button will display
  const [userId, setUserId] = useState(null);
  const [loaderspin, setLoaderspin] = useState(true);
  const [showAppointment, setShowAppointment] = useState(true);
  const [notmember, setNotmember] = useState(true);
  const [inputClicked, setInputClicked] = useState(false);

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const [callMessage,setCallMessage]=useState('Call initiated')
  const [errcallMessage,setErrcallMessage]=useState(false)

  useEffect(() => {
    
    // Clear localStorage
    if(localStorage.getItem("user-email")){
      
    }else{
      localStorage.clear();
      window.location.reload();
    }
    
  }, []); 
  console.log(success,"succes")
  // global search API Call
  const fetchData = async (globalSearch) => {
    try {
      const response = await axios.get(`https://userprofile.vetic.in/search/?text=${globalSearch}`);
      setGlobaldata(response?.data?.data)
      if(response?.data?.data.length===0){
        setInformation(false);
        setFlage(true);
      } else {
        setUserdata(response?.data?.data)
        setFlage(true)
        setInformation(true)
      }       
      setLoaderspin(false)
    } catch (error) {
      console.error(error);
    }
  }; 

  // Waiting list API Call
  useEffect(() => {    
    const fetchData = async () => {
      try {       
        const response = await axios.get(`https://userprofile.vetic.in/waiting-list/?vetbuddy_clinic_id=${vet_clinic_id}&vetbuddy_instance_id=${vet_instance_id}`);       
        setMydata(response?.data?.data);
        setUserdata(response?.data?.data);
        setInformation(true);
        setLoaderspin(false);
      } catch (error) {
        console.error(error);
        setInformation(false);
      }
    };
    if(vet_instance_id!==null){
      fetchData();
    }
    const intervalId=  setInterval(() => {
      if(vet_clinic_id){
        fetchData()
      }
    }, 300000);
    return () => {
      clearInterval(intervalId);
    };
  },[vet_instance_id,vet_clinic_id]);

  // clinic list API Call
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_CLINIC_LIST}/clinic-org/workspace/list`);
        const dist_id= localStorage.getItem('dist_id');
        if(dist_id==='all'){
          setClinics(response?.data?.data?.results)
        } else {
          const filteredData = response?.data?.data?.results?.filter(item => item.district === dist_id); 
          setClinics(filteredData)
        } 
      } catch (error) {
        console.error(error);
      }
    };
    fetchData()
  }, []);

  const ClinicSearch = (vet_I_id,vet_c_id, clinic_name) => {   
    if(clinic_name!==''){
      setSelectedClinic(clinic_name);           //to update the useeffect to fetch new selected clinic
      localStorage.setItem('vet_id',vet_I_id );
      localStorage.setItem('vet_clinic_id',vet_c_id );
      localStorage.setItem('clinic_name',clinic_name );
      setUserdata(mydata);
      setInformation(true);
      setGlobalSearch('');
    }
    setFlage(true);
  };

  useEffect(() => {    
    const clinicname= localStorage.getItem('clinic_name');
    const clinic_id= localStorage.getItem('vet_clinic_id');
    const vet_instance= localStorage.getItem('vet_id');
    setSelectedClinic(clinicname);
    setVet_clinic_id(parseInt(clinic_id))
    setVet_instance_id(vet_instance)
    setButton_display(clinicname?.split(",")[1]?.slice(0, 13))  
  },[selectedClinic]);

  //manager for single user
  useEffect(() => {    
    const clinicname= localStorage.getItem('clinic_name');
    const clinic_id= localStorage.getItem('vet_clinic_id');
    const vet_instance= localStorage.getItem('vet_id');
    setSelectedClinic(clinicname);
    setVet_clinic_id(parseInt(clinic_id))
    setVet_instance_id(vet_instance)
    setButton_display(clinicname?.split(",")[1]?.slice(0, 13))
    const notaccess=localStorage.getItem('permit')
    setNotmember(notaccess==="false"?false:true)
  },[])
    
  const handelWaitinglist = (e) => {
    e.preventDefault()
    setUserdata(mydata)
    setGlobalSearch('')
    setInformation(true)
    setFlage(true)
    setShowAppointment(true)
    setLoaderspin(false)  
  };

  useEffect(() => {
    const  handelEventTrigger=()=>{
      const headers = {
        'Content-Type': 'application/json'
      };
      const data = {
        "event_type": "SCREEN_OPENED",
        "scr_id": "Home_Page",
        "timestamp":Date.now() ,
        "source":"99",
        "member_id": localStorage.getItem("user-email"),
        "clinic_name": localStorage.getItem("clinic_name")
      };
      axios.post("https://webhooks.vetic.in/retail-app/click-logs/", data, { headers })
      .then(response => console.log(response.data))
      .catch(error => console.log('error', error));
    }
    handelEventTrigger();
  }, []);

  const handelEventTrigger = (value) => {
    const headers = {
      'Content-Type': 'application/json'
    };   
    axios.post("https://webhooks.vetic.in/retail-app/click-logs/", value, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.log('error', error));
  };       

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      handelChange(event);
    }
  };

  const handleSearchTextEntered = () => {
    const headers = {
      'Content-Type': 'application/json'
    };  
    const datas = {
      "event_type": "CLICK",
      "scr_id": "Home_Page",
      "subaction": "search_bar_entered",
      "timestamp":Date.now() ,
      "source":"99",
      "member_id": localStorage.getItem("user-email")
    }; 
    axios.post("https://webhooks.vetic.in/retail-app/click-logs/", datas, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.log('error', error));
  }

  const handelChange =(e)=>{
    e.preventDefault()
    setGlobal_id(globalSearch)      //fire global search api on this igone this  
    fetchData(globalSearch)  //fire global search api on this 
    const dataLog = {
      "event_type": "CLICK",
      "scr_id": "Home_Page",
      "subaction": "search_clicked",
      "timestamp":Date.now() ,
      "source":"99",
      "member_id": localStorage.getItem("user-email"),
      "text_searched": globalSearch
    };
    handelEventTrigger(dataLog);
    if(globalSearch===''){
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000);
      setInformation(true)
    } else {
      setLoaderspin(true)
      setShowAppointment(false)
    }  
  };

  setTimeout(() => {
    setLoaderspin(false)
  }, 3000);

  const handleClick = (value, check) => {
    setFlage(check)
    setUserId(value?.unique_id)// unique_id    
  };

  console.log(userId);

  const Loader = () => {
    return (
      <div className='loader-body'> 
        <div class="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    )
  };
  
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
      setFlage(true)
      setUserdata(mydata)
      setGlobalSearch('')
      setShowAppointment(true)
    };            
  },[flage,mydata])

  const handleLogout = () => {
    localStorage.setItem('authdata',null);
    localStorage.setItem('vet_id',null);
    localStorage.setItem('vet_clinic_id',null);
    localStorage.setItem('clinic_name',null);
    localStorage.setItem('state',null );
    localStorage.setItem('permit',null);
    localStorage.setItem('dist_id',null );
    localStorage.setItem('error',null );
    localStorage.setItem("user-email",null)
    window.location.reload() 
  };

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpen(false);
  };
  
// console.log(callMessage,"msg")
console.log(errcallMessage,"msg")

  return (
    <>
      <Box className="container">
        <Box className="header">
          <Box className="header-top">
            <Box className="logosection">
              <Box onClick={()=>setFlage(true)}>
                <img src='https://vetic-img.s3.ap-south-1.amazonaws.com/website/clinics/veticlogo.svg' alt="logo" className='logo-img'/>
              </Box>
            </Box>
            <Box className="clinicsection">
              <Box className="header-username">
                <p className='para'  >{localStorage.getItem("user-email")}</p>
                <p><FiLogOut style={{color:"red" ,cursor:"pointer"}}  title="Log Out" onClick={()=>handleLogout()}/></p>     
              </Box>
              <p className='para'>{selectedClinic}</p>
            </Box>
          </Box>
          <Box className="headermiddle">
            <Box className="logosection">
              <Box className="button-area"> 
                <Box className="input-box">
                  <p style={{margin:"0"}} onClick={handelWaitinglist}>
                    <AiFillHome style={{fontSize:"25px",cursor:"pointer"}}/>
                  </p>
                  <input type='text' className='input-field'
                    placeholder='Enter Name, Pet Name or Number'
                    maxLength={20}
                    value={globalSearch} required
                    onKeyDown={handleInputKeyDown}
                    onChange={(event) => {
                      setGlobalSearch(event.target.value);
                    }}
                    onClick={handleSearchTextEntered}
                  />         
                </Box>
                <Box>
                  <button className='search-button' onClick={handelChange} >Search</button>        
                </Box>
              </Box> 
              {error&&<span className='global-error'>please enter a name or number</span>}
            </Box>
            <Box className="clinicsection">
              <Box className="clinic-drowpdown">
                {notmember&&<Popupclinicselection data={clinics} ClinicSearch={ClinicSearch}  button_display={button_display} />}
              </Box>
            </Box>
          </Box> 
        </Box>    
        {success&&<Snackbar open={open} autoHideDuration={6000} onClose={handleClose} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        >
          <Alert onClose={handleClose} severity={`${errcallMessage?"error":'success'}`} sx={{ width: '100%' }}>
           {callMessage}
          </Alert>
          
        </Snackbar>  }

        {loaderspin?<Loader />:flage ?(userdata===null||!information?<Usernotfound />:<Userlist handleClick={handleClick} data={userdata} showAppointment={showAppointment} unique_id={userId} />) :<UserInfo  setFlage={setFlage} unique_id={userId} clinics={clinics} contactDetails={contactDetails} setSuccess={setSuccess} setCallMessage={setCallMessage} setOpen={setOpen} setErrcallMessage={setErrcallMessage} open={open}/> }
      </Box>
    </>
  );
};

export default Dashboard;