import axios from 'axios';
import 'firebase/database';
import Dashboard from "../user/userList/Index";
import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from "../../../firebase";

function App() {
  const [flage, setFlage] = useState(false);
  const [errr, setErrr] = useState(false);
  const [screen, setScreen] = useState(false);
  const [contactDetails,setContactDetails]=useState([])

  useEffect(() => {
    const clinicname = localStorage.getItem('state') === 'true' ? true : false;
    const err = localStorage?.getItem('error') === 'true' ? true : false;
    setErrr(err);
    setFlage(clinicname);
    setScreen(true);
    fetch('https://static.vetic.in/api/website/clinicmanager/')
    .then(response => response.json())
    .then(data => {
      const mydata=data?.data?.page_content?.body?.main?.clinic?.data
      const myObject = mydata?.find(obj => obj.email === localStorage.getItem("user-email-address"));
      setContactDetails(myObject?.contacts)
      localStorage.setItem('authdata', JSON.stringify(mydata));  
    })
    .catch(error => console.error(error));
  }, []);
  
  useEffect(() => {
    const handelEventTrigger = () => {
      const headers = {
        'Content-Type': 'application/json'
      };
      const data = {
        "event_type": "SCREEN_OPENED",
        "scr_id": "Signup_Screen",
        "timestamp": Date.now(),
        "is_login": false,
        "source": "99"
      };
      axios.post("https://webhooks.vetic.in/retail-app/click-logs/", data, { headers })
        .then(response => console.log(response.data))
        .catch(error => console.log('error', error));
    };
    handelEventTrigger();
  }, []);
  
  return (
    <div className="App">
      {screen&&
        <>
          {flage ? <Dashboard contactDetails={contactDetails} /> :
            <div className='sign-in'>
              <div className='login-img'>
                <img src="https://assets.vogue.in/photos/62502b8d90038a40f11ff27c/1:1/w_960,c_limit/Dog%202.png" alt="hbdjh" />  
              </div>
              <button onClick={signInWithGoogle}>Sign In With Google</button>
            </div>
          }
          {errr&&<p className='access-d'>Sorry! please use your authorized gmail id.</p>}
        </>
      }
    </div>
  );
};
  
export default App;