import { Paper } from "@mui/material";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";

const DivView = styled("div")(({ theme }) => ({
  borderradius: 12,
  [theme.breakpoints.up("sm")]: {
    position: "relative",
    top: '10%',
    width: '100%',  
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: '20%',
    left:'0',
    right: "0",
    width: '100%',
  },
}));

const FormView = styled(Paper)(({ theme }) => ({
  borderradius: 8,position: "fixed",
  [theme.breakpoints.down("sm")]: {
    padding: '14px 16px',
    width:'20rem',
  },
  [theme.breakpoints.up("sm")]: {
    padding: '1.8rem',
    width: '53%',
    borderRadius:"5px",
    position: "fixed",
  },
  [theme.breakpoints.up('md')]: {
    width: '43%',
  },
}));
  
export default function BasicModal({ text, value }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {setOpen(true)}
  
  return (
    <>
      <span onClick={handleOpen}>{text} </span> 
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DivView>
          <FormView>
            <p>{value ||"No Data Available"}</p>     
          </FormView>
        </DivView>
      </Modal>
    </>
  );
};