const Notfound = () => {
  return (
    <>
      <div className="empty-state">
        <div className="empty-state__content">
          <div className="empty-state__icon">
            <img src="https://vetic-img.s3.ap-south-1.amazonaws.com/website/clinics/Web.png" alt="" />
          </div>
          <div className="empty-state__message">No records found !</div>
          <div className="empty-state__help">Please check on Vetbuddy once.</div>
        </div>
      </div>
    </>
  );
};

export default Notfound;