import React from 'react';


const User = () => {

  return (
    <div>
        <p>user 1</p>
        <p>user 2</p>
        <p>user 3</p>
        <p>user 4</p>
    </div>
  );
};

export default User;
