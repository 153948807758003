import './App.css';
import User from './Product'; 
import Home from './component/pages/home';
import React, { useEffect, useState } from 'react';
import StockForm from './component/pages/stockForm';
import PageNotFound from './component/pages/NotFound/index';
import UserDetails from './component/pages/user/userinfo/Index';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  const [uuId, setUuId] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uniqueId = searchParams.get('unique_id');
    setUuId(uniqueId);
  }, []);

  useEffect(() => {
    if (uuId) {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`https://userprofile.vetic.in/user-detail/?unique_id=${uuId}`, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
    }
  }, [uuId]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user" element={<User />} />
        <Route path="/user/:userId" element={<UserDetails />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/stock-incomplete-form" element={<StockForm uuId={uuId} />} />
      </Routes>
    </Router>
  );
};

export default App;