import * as React from "react";
import Modal from "@mui/material/Modal";
import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BsFillChatDotsFill } from 'react-icons/bs';

const DivView = styled("div")(({ theme }) => ({
  borderradius: 12,
  [theme.breakpoints.up("sm")]: {
    position: "relative",
    top: '20%',
    width: '100%',  
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: '30%',
    left:'auto',
    width: '100%', 
    display: 'flex',
    justifyContent: 'center',
  }
}));

const FormView = styled(Paper)(({ theme }) => ({
  borderradius: 8,position: "fixed",
  [theme.breakpoints.down("sm")]: {
    padding: '14px 16px',
    width:'20rem',
  },
  [theme.breakpoints.up("sm")]: {
    padding: '2rem',
    width:'20rem',
    height:"auto"
  },
  [theme.breakpoints.up('md')]: {
    width: '43%',
  },
}));
  
export default function BasicModal({ feedback_value }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const Call_out = feedback_value?.client_callouts?.callouts;
  
  return (
    <>
      <span onClick={handleOpen}>
        <BsFillChatDotsFill style={{color:"#2675E2"}} />
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DivView>
          <FormView>
            <Box className="shift-clinics-call">
              {Call_out?.map((value, id) => {
                return <p key={id} style={{color:"#242526",margin:"0"}}>{value}</p>
              })} 
            </Box>
          </FormView>
        </DivView>
      </Modal>
    </>
  );
};