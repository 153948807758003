import React from 'react';
import Box from '@mui/material/Box'; 
import { MdCancel } from 'react-icons/md';
import { MdCheckCircle } from 'react-icons/md';

export default function Vaccination({ data }) {
  const Vaccination = data?.vaccines;
  const Dog_data = Vaccination?.filter(item => item.pet_type.toLowerCase() === "dog")[0]?.details;
  const Cat_data = Vaccination?.filter(item => item.pet_type.toLowerCase() === "cat")[0]?.details;

   const handelVaccinationStatus = (value) => {
      const date = value?.split("-")?.reverse()?.join('-');
      const dueDate = new Date(date);
      const today = new Date();
      return dueDate < today
   };

   const handelVaccinationStatusnodata = (value) => {
      return value === '-' ? true : false
   };

   const vaccinationSorting = (value) => {
      const Sortdata = value?.sort((a, b) => a.name.localeCompare(b.name));
      return Sortdata
   };

   const vaccinationDate = (value) => {
      const [day, monthNum, year] = value?.split("-");
      const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
      const month = monthNames[parseInt(monthNum) - 1];
      const formattedDate = `${day}-${month}-${year?.slice(-2)}`;
      return formattedDate==="-undefined-undefined"?"No Data":formattedDate
   };

   return (
      <>
         <Box className="vaccination-info">
            {Dog_data?.length > 0 && 
               <>  
                  <Box className="vaccination-table-header">
                     <Box className="v-header"><h4>Pet Name</h4></Box>
                     {VaccinationHeader?.map((value, id) => {
                        return <Box className="v-header" key={id}>
                           <h4><span>{value.header}</span></h4>
                        </Box>
                     })}                                          
                  </Box>
                  <Box>                      
                     {Dog_data?.map((value, id) => {
                        return <Box key={id}>
                           <Box className="vaccination-history">
                              <p className="petname-invaccination">{value?.pet_name}</p>
                              <Box className="inside-vaccination">
                                 {vaccinationSorting(value?.vaccinations)?.map((val, id) => {
                                    return <p key={id}>{vaccinationDate(val?.due_on)} &nbsp; 
                                       <span>
                                          {handelVaccinationStatus(val?.due_on) ? 
                                             <span style={{display:"block"}}>(Overdue)</span> : 
                                             (handelVaccinationStatusnodata(val?.due_on)) ? 
                                             <span><MdCancel style={{color:`#F94E63`}}/></span> :
                                             <span><MdCheckCircle style={{color:`#199016`}}/></span>
                                          }
                                       </span>
                                    </p>
                                 })}
                              </Box>                                                           
                           </Box>
                        </Box>
                     })}
                  </Box> 
               </>
            }   
            <p style={{marginTop:"1rem"}}></p>
            {Cat_data?.length > 0 && 
               <>  
                  <Box className="vaccination-table-header-cat" >
                     <Box className="vc-header"><h4>Pet Name</h4></Box>
                     {VaccinationHeaderCat?.map((value, id) => {
                        return <Box className="vc-header" key={id}>
                           <h4><span>{value.header}</span></h4>
                        </Box>
                     })}
                  </Box>
                  <Box>
                     {Cat_data?.map((value, id) => {
                        return <>
                           <Box className="vaccination-historyd" key={id}>
                              <p className="petname-invaccinationj">{value?.pet_name}</p>
                              <Box className="inside-vaccinationh">
                                 {vaccinationSorting(value?.vaccinations)?.map((val,id) => {
                                    return <p key={id}>{vaccinationDate(val?.due_on)} &nbsp; 
                                       <span>
                                          {handelVaccinationStatus(val?.due_on) ? 
                                             <span>(Overdue)</span> :
                                             (handelVaccinationStatusnodata(val?.due_on)) ? 
                                             <span><MdCancel style={{color:`#F94E63`}}/></span> :
                                             <span><MdCheckCircle style={{color:`#199016`}}/></span>
                                          }
                                       </span>
                                    </p>
                                 })}
                              </Box>    
                           </Box>
                        </>
                     })}
                  </Box> 
               </>
            }                     
         </Box>
      </>
   );
};

const VaccinationHeader = [
   {
     header:"Anti Rabies"
   },
   {
      header:"Corona"
   },
   {
    header:"DHPPIL "
   },
   {
    header:"KC"
   }
];

const VaccinationHeaderCat = [
   {
    header:"Anti Rabies"
   },
   {
    header:"CRP"
   }
];