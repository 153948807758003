import Modal from "@mui/material/Modal";
import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MdExpandMore } from 'react-icons/md';
import  React, { useEffect, useState } from "react";

const DivView = styled("div")(({ theme }) => ({
  borderradius: 12,
  [theme.breakpoints.up("sm")]: {
    position: "relative",
    top: '30%',
    width: '100%',  
    display: 'flex',
    justifyContent: 'center',
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: '20%',
    left:'0',
    right: "0",
    width: '100%',
  },
}));

const FormView = styled(Paper)(({ theme }) => ({
  borderradius: 8,position: "fixed",
  [theme.breakpoints.down("sm")]: {
    padding: '14px 16px',
    width:'20rem',
  },
  [theme.breakpoints.up("sm")]: {
    padding: '1.8rem',
    width: '53%',
    borderRadius:"20px",
    position: "fixed",
  },
  [theme.breakpoints.up('md')]: {
    width: '43%',
  },
}));
  
export default function BasicModal({ data, ClinicSearch, button_display }) {
  const [open, setOpen] = useState(false);
  const [mydata, setMydata] = useState(data);
  const [clinicname, setClinicname] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [vetclinicid, setVetclinicid] = useState('');
  const [vet_instance_id, setVet_instance_id] = useState('');
  
  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    setOpen(true);
    setClinicname('');
    setMydata(data);
  };
  
  useEffect(() => {
    if(data){
     setMydata(data);
    }
  },[data]);

  const ClinicSelection = ( instanc_id, clinic_id, name, id ) => {
    setVet_instance_id(instanc_id);
    setVetclinicid(clinic_id);
    setClinicname(name);
    setSelectedOption(id);
  };

  const handelSearch = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setMydata(data);
    } else {
      const results = mydata.filter(post => {
        return post.name.toLowerCase().includes(e.target.value.toLowerCase())
      })
      setMydata(results);
    }
    setClinicname(e.target.value);
  };

  useEffect(() => {
    const clinic_id = localStorage.getItem('vet_clinic_id');
    setSelectedOption(clinic_id);
  },[]);

  return (
    <>
      <button className="clinic-input" onClick={handleOpen}>
        <span> {button_display||"Select Clinic"}</span> 
        <span><MdExpandMore style={{paddingTop:"3px",fontSize:"2.7vw"}} /></span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DivView>
          <FormView>
            <Box className="shift-clinics">
              <Box className="button-area" style={{width: "100%"}}>
                <Box className="input-box-shift">
                  <input type='text' className='input-field-s' placeholder='Search our clinic' value={clinicname} id="clinic-bdr" onChange={handelSearch} required />
                </Box>
                <Box className="clinic-shift-btn-pop">
                  <span  onClick = {() => setOpen(false)}> 
                    <button className='search-button' onClick = {(e) => ClinicSearch( vet_instance_id, vetclinicid, clinicname )}>Select</button>
                  </span>
                </Box>
              </Box> 
              <Box className="clinic-list-spacific">
                {mydata?.map((value, id) => {
                  return <Box key={id} className="clinic-list-shift"> 
                    <span className={`form-control`}> 
                      <input type="radio"
                       id={value.id} 
                        checked={selectedOption === value.id}
                        className={`input-radio-button`}  
                        name="fav_language"   
                        value={value.vetbuddy_clinic_id}  
                        onChange={(e)=>ClinicSelection(value.vetbuddy_instance_id, value.vetbuddy_clinic_id, value.name, value.id)} 
                      /> 
                    </span>
                    <span><label htmlFor={value.id}>{value.name}</label></span> 
                  </Box>        
                })}
              </Box>
            </Box> 
          </FormView>
        </DivView>
      </Modal>
    </>
  );
};