import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';

const StockForm = ({ uuId }) => {
    const [requester, setRequester] = useState("");
    const [clientName, setClientName] = useState("");
    const [quantity, setQuantity] = useState({});
    const [clientId, setClientId] = useState("");
    const [itemValues, setItemValues] = useState({});
    const [showAddMore, setShowAddMore] = useState(true);
    const [itemBoxes, setItemBoxes] = useState([1]);
    const [remarks, setRemarks] = useState("");
    const [otherItem, setOtherItem] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [clinicList, setClinicList] = useState([]);
    const [clinicName, setClinicName] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const location = useLocation();
    const autocompleteRef = useRef(null); 

    useEffect(() => {
        // Close the dropdown when clicking outside the Autocomplete input box
        const handleClickOutside = (event) => {
          if (
            autocompleteRef.current &&
            !autocompleteRef.current.contains(event.target)
          ) {
            setOpenDropdown(false);
          }
        };
    
        const handleOutsideClick = (event) => {
          handleClickOutside(event);
        };
    
        document.addEventListener('click', handleOutsideClick);
    
        return () => {
          document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleSearch = async (searchTerm, itemBox) => {
        try {
          if (searchTerm.trim() === '') {
            setSearchResults((prevResults) => ({ ...prevResults, [itemBox]: [] }));
            return;
          }
      
          const response = await axios.get(
            `https://org.vetic.in/clinic-org/stock-items/search/?search=${searchTerm}`
          );
          setSearchResults((prevResults) => ({
            ...prevResults,
            [itemBox]: response.data.data,
          }));
        } catch (error) {
          console.error(error);
        }
    };        

    useEffect(() => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        fetch("https://org.vetic.in/clinic-org/workspace/list", requestOptions)
        .then(response => response.json())
        .then((data) => {
          const clinicListArray = data.data.results.map((item) => item.name);
          setClinicList(clinicListArray);
        })
        .catch(error => console.log('error', error));
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const nameOfRequester = searchParams.get('name_of_requester');
        const clientName = searchParams.get('client_name');
        const clientMobile = searchParams.get('client_mobile');
        const clientId = searchParams.get('client_id');
        setRequester(nameOfRequester || '');
        setClientName(clientName || '');
        setQuantity(clientMobile || '');
        setClientId(clientId || '');
    }, [location.search]);

    const handleAddMore = () => {
        const newItemBoxes = [...itemBoxes];
        const nextItem = itemBoxes.length + 1;
        newItemBoxes.push(nextItem);
        setItemBoxes(newItemBoxes);
      
        if (nextItem >= 5) {
          setShowAddMore(false);
        }
      
        setItemValues((prevState) => ({
          ...prevState,
          [nextItem]: "",
        }));
        setOtherItem((prevState) => ({
          ...prevState,
          [nextItem]: undefined,
        }));
        setQuantity((prevState) => ({
          ...prevState,
          [nextItem]: "",
        }));
    };          

    function Submit() {
        setIsSubmitting(true);
        setIsDialogOpen(true);
        setTimeout(() => {
          setIsSubmitting(false);
          setIsDialogOpen(true);
        }, 2000);
        
        const itemNames = Object.values(itemValues).map((itemName, index) => {
            const itemQuantity = quantity[index + 1] || "";
            return {
              item_name_sub: itemName.item_name,
              quantity: itemQuantity,
            };
        });
        
        const payload = {
          name_of_requester: requester,
          client_name: clientName,
          client_id: clientId,
          remarks: remarks,
          item_name: itemNames,
          user_id: uuId,
          clinic_name: clinicName
        };
      
        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload),
          redirect: "follow"
        };
      
        fetch("https://org.vetic.in/clinic-org/stock-incomplete/", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            window.close();
          })
          .catch((error) => console.log("error", error));
    }  

    const handleDialogClose = () => {
        setIsSubmitting(false);
        setIsDialogOpen(false);
    };

    const isSubmitDisabled =
    requester === "" ||
    clientName === "" ||
    clinicName === "" ||
    clientId === "" ||
    remarks === "" ||
    itemBoxes.some((itemBox) => {
        const itemName = otherItem[itemBox] ? otherItem[itemBox] : itemValues[itemBox];
        const itemQuantity = quantity[itemBox];
        return itemName && !itemQuantity;
    });

    const handleItemChange = (itemBox, value) => {
        if (value === 'Other') {
          setOtherItem((prevState) => ({ ...prevState, [itemBox]: '' }));
        } else {
          setOtherItem((prevState) => ({ ...prevState, [itemBox]: undefined }));
        }
        setItemValues((prevState) => ({ ...prevState, [itemBox]: value }));
      
        // Clear the search results for the specific item box
        setSearchResults((prevResults) => ({ ...prevResults, [itemBox]: [] }));
      
        // Fetch search results for the specific item box
        handleSearch(value, itemBox);

        // Reset the quantity for the specific item box
        setQuantity((prevState) => ({ ...prevState, [itemBox]: '' }));
    };              
      
    const handleOtherInputChange = (itemBox, value) => {
        setOtherItem(prevState => ({ ...prevState, [itemBox]: value }));
    };

    return (
        <Box>
            <Box>
                <h1 className="heading">Stock Incomplete Form</h1>
            </Box>
            <Box style={{marginTop: "2rem"}}>
                <Box className="box2" component="form" noValidate autoComplete="off">
                    <p className="desc">Requester Name</p>
                    <TextField
                        id="outlined-basic"
                        label="Requester Name"
                        required
                        variant="outlined"
                        value={requester}
                        onChange={(e) => {
                            setRequester(e.target.value);
                        }}
                        className='text-field'
                    />
                </Box>
                <Box className="box2" component="form" noValidate autoComplete="off">
                    <p className="desc">Client Name</p>
                    <TextField
                        id="outlined-basic"
                        label="Client Name"
                        required
                        variant="outlined"
                        value={clientName}
                        onChange={(e) => {
                            setClientName(e.target.value);
                        }}
                        className='text-field'
                    />
                </Box>
                <Box className="box2" component="form" noValidate autoComplete="off">
                    <p className="desc">Client ID</p>
                    <TextField
                        id="outlined-basic"
                        label="Client ID"
                        type="number"
                        required
                        variant="outlined"
                        value={clientId}
                        onChange={(e) => {
                            setClientId(e.target.value);
                        }}
                        className='text-field'
                    />
                </Box>
                <Box className="box3">
                    <p className="titles">Clinic</p>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        required
                        options={clinicList}
                        sx={{ width: 250 }}
                        onInputChange={(event, newInputValue, reason) => {	
                        if (reason !== "clear") {	
                            setClinicName(newInputValue);	
                        }	
                        }}	
                        renderInput={(params) => <TextField {...params} label="Clinic" />}	
                    />
                </Box>
                <Box>
                    {itemBoxes.map((itemBox) => (
                        <Box key={itemBox}>
                            <Box className="box3">
                                <p style={{ fontFamily: "Poppins, sans-serif", width: "14rem" }}>Item {itemBox}</p>
                                <Box ref={autocompleteRef}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        autoComplete="off"
                                        required
                                        options={searchResults[itemBox] || []}
                                        getOptionLabel={(option) => option.item_name}
                                        value={itemValues[itemBox] || null}
                                        onChange={(event, newValue) => handleItemChange(itemBox, newValue)}
                                        open={openDropdown && Boolean(
                                            searchResults[itemBox] && searchResults[itemBox].length
                                        )}
                                        onOpen={() => setOpenDropdown(true)} // Set the dropdown state to open
                                        onClose={() => setOpenDropdown(false)} // Set the dropdown state to closed
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Item Name"
                                                type="text"
                                                required
                                                variant="outlined"
                                                onChange={(e) => handleSearch(e.target.value, itemBox)}
                                                className="text-field"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: <React.Fragment></React.Fragment>, // Remove the dropdown icon
                                                }}
                                            />
                                        )}
                                        noOptionsText="" // Remove the "No options" message
                                    />
                                </Box>
                            </Box>
                            <Box className="box2" component="form" noValidate autoComplete="off">
                                <p className="desc">Quantity</p>
                                <TextField
                                    id={`quantity-${itemBox}`}
                                    label="Quantity"
                                    type="number"
                                    required
                                    variant="outlined"
                                    value={quantity[itemBox] || ""}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (input === "" || (Number(input) >= 1 && Number.isInteger(Number(input)))) {
                                          setQuantity({ ...quantity, [itemBox]: input });
                                        }
                                    }}
                                    className='text-field'
                                    disabled={!itemValues[itemBox]}
                                    inputProps={{
                                        min: 1, // Ensures the minimum value is 1
                                    }}
                                />                   
                            </Box>
                            <Box className="other_main_container">
                                {itemValues[itemBox] === "Other" && otherItem[itemBox] !== undefined && (
                                    <Box className="box3">
                                        <p className="titles">Other Item Name</p>
                                        <TextField
                                            id={`other-item-${itemBox}`}
                                            label="Other Item Name"
                                            variant="outlined"
                                            value={otherItem[itemBox] || ""}
                                            onChange={(e) => handleOtherInputChange(itemBox, e.target.value)}
                                            className='text-field'
                                        />
                                    </Box>
                                )}                            
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box>
                    {showAddMore && (
                        <Box className='add_button_container'>
                            <Button className="add_button" onClick={handleAddMore}>Add More</Button>
                        </Box>
                    )}
                </Box>
                <Box className="box2" noValidate autoComplete="off">
                    <p className="desc">Remarks</p>
                    <TextField
                        id="outlined-multiline-static"
                        label="Remarks"
                        multiline
                        rows={3}
                        onChange={(e) => {
                            setRemarks(e.target.value);
                        }}
                        className='text-field'
                    />
                </Box>
                <Box style={{marginTop: "1rem", textAlign: "center"}}>	
                    <Button		
                        color="primary"	
                        variant="contained"	
                        style={{	
                            marginBottom: "2rem",	
                        }}	
                        disabled={isSubmitting || isSubmitDisabled}
                        onClick={Submit}>	
                        {isSubmitting ? 'Submitting' : 'Submit'}
                    </Button>	
                    {isDialogOpen && (
                        <div className="dialog">
                            <p>Form submitted successfully!</p>
                            <Button onClick={handleDialogClose} color="primary" variant="contained">OK</Button>
                        </div>
                    )}
                </Box>	
            </Box>
        </Box>
    );
};

export default StockForm;