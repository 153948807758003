import axios from 'axios';
import Box from '@mui/material/Box'; 
import React, { useState, useEffect } from 'react';
import { auth } from "../../../../firebase";

const Request = ({ data, re_list }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [input, setInput] = useState('');
  const [flage, setFlage] = useState(false);
  const [active, setActive] = useState(false);

   console.log(selectedOption,"selected option",input)
   console.log(input,"input")

  const handleOptionChange = (value) => {
    if (selectedOption.includes(value)) {
      setSelectedOption(selectedOption.filter(fruit => fruit !== value));
    } else {
      setSelectedOption([...selectedOption, value]);
    }
  };
   
  const speacialRequiest = (e) => {
    e.preventDefault();
    setFlage(true);
    if (input !== '') {
      selectedOption?.push(input)
    }     
    
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const currentUser = auth.currentUser;
    const userName = currentUser.displayName;
    const userEmail = currentUser.email;
    var raw = JSON.stringify({
      "unique_id": data?.unique_id,
      "special_requests": [...new Set(selectedOption)],
      "filled_by": {
        "name": userName,
        "email": userEmail
      }
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_WAITNG_LIST_URL}/special-requests/`, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
    setInput('');
    setActive(false)
    setTimeout(() => {
      setFlage(false);
      setSelectedOption([]);
    }, 2000);
  };

  const handleCheckbox = () => {
    setActive(!active);
  };

  const handleInput = (e) => {
    setInput(e.target.value);
    setActive(true);
  };

  useEffect(() => {
    if (selectedOption.length > 0) {
      const event = {
        "event_type": "CLICK",
        "scr_id": "Profile_Page",
        "subaction": "special_request_selected",
        "timestamp": Date.now(),
        "source": "99",
        "member_id": localStorage.getItem("user-email"),
        "user_id": data?.unique_id,
        "items_selected": selectedOption
      };
      handelEventTrigger(event);
    }
  }, [selectedOption]);

  const handelEventTrigger = (event) => {
    const headers = {
       'Content-Type': 'application/json'
    };
    axios.post("https://webhooks.vetic.in/retail-app/click-logs/", event, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.log('error', error));
  };

  const submitEvent = () => {
    const event = {
       "event_type": "CLICK",
       "scr_id": "Profile_Page",
       "subaction": "special_request_submitted",
       "timestamp": Date.now(),
       "source": "99",
       "member_id": localStorage.getItem("user-email"),
       "user_id": data?.unique_id,
       "items_selected": selectedOption
    };
    handelEventTrigger(event);
  }; 

  return (
    <>
      <Box className="user-requiest">
        <Box>
        {re_list?.map((value, id) => {
          return <Box key={id} className="clinic-list-shift" sx={{marginTop:"8px"}}>
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customCheck1-${value.name}`}      
              onChange={() => handleOptionChange(value.name)}
            />
            <label
              className="custom-control"
              htmlFor={`customCheck1-${value.name}`}>
              {value.name}
            </label>
          </Box>        
        })}      
        <Box className="clinic-list-shift" sx={{marginTop:"8px"}}> 
          <input type="checkbox" id='o' name="fav_language" value="HTMfL" checked={active} onChange={handleCheckbox} className="input-radio-button" style={{fontSize:"12px"}} />
          <span> 
            <label htmlFor='o'>Others</label>
          </span> 
        </Box> 
        </Box>
        <Box className="requist-text-area">
          <textarea className='teaxt-area' value={input} onChange={(e) => handleInput(e)}></textarea>
          <Box sx={{display:"flex", flexDirection:"column"}} onClick={() => submitEvent()}>
            <button className={`${selectedOption.length === 0 && input === '' ? 'search-button-disable' : 'search-button'}`} disabled={selectedOption.length === 0 && input === ''} style={{width:"12%"}} onClick={(e) => speacialRequiest(e)}>Submit</button>
            {flage && <p className='sp-req'>Request send</p>}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Request;