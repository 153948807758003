import React from 'react';
import Box from '@mui/material/Box'; 

const Food = ({ data }) => {
  return (
    <>
      <Box className="user-info-box-food">
        <h4 className='user-name-info' style={{marginTop:"0"}}>{Array.isArray(data?.food_purchase_detail)?"Recent top purchases":"No records found"}</h4>
        {data?.food_purchase_detail?.map((value,id) => {
          return <Box className="foodlist">
            <li></li> 
            <p className='para-item' key={id}>{value?.food_sku_name} 
              <span>({value?.food_purchase_date})</span>
            </p>
          </Box>
        })}                 
      </Box>
    </>
  );
};

export default Food;