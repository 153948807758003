import React from 'react';

function index() {
  return (
    <div style={{backgroundColor:"#f7f7f6",display:"flex",justifyContent:"center",height:"100vh"}}>    
      <img src="https://vetic-img.s3.ap-south-1.amazonaws.com/website/admin-tool/1-10.webp" alt="notfound" />
    </div>
  );
};

export default index;