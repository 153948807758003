import Rca from './Rca';
import axios from 'axios';
import CallOut from './callOut';
import Box from '@mui/material/Box'; 
import {MdCancel} from 'react-icons/md';
import { MdCheckCircle } from 'react-icons/md';
import {HiOutlineFilter} from 'react-icons/hi';
import React,{useEffect, useState} from 'react';

const Userlist = ({ handleClick, data, showAppointment, userId }) => {
   const [flage, setFlage] = useState(true);
   const [mydata, setMydata] = useState(null);
   const [filter, setFilter] = useState(false);
   const [filterid, setFilterid] = useState(null);

   useEffect(() => {
      if(data){
         setMydata(data);
         const dataLog = {
            "event_type": "SCREEN_OPENED",
            "scr_id": "List_Page",
            "timestamp":Date.now() ,
            "source":"99",
            "member_id": localStorage.getItem("user-email"),
            "Items_listed": data?.length
         };
         handelEventTrigger(dataLog);
      }
   },[data]);

   const handelEventTrigger = (value) => {
      const headers = {
         'Content-Type': 'application/json'
      };
      axios.post("https://webhooks.vetic.in/retail-app/click-logs/", value, { headers })
      .then(response => console.log(response.data))
      .catch(error => console.log('error', error));
   };
   
    // Appointment filtering item action
   const handelAppointment = (id, value) => {
      setFilterid(id);
      setFilter(true);
      setFlage(false);
      const filterData=data?.filter((val) => { return value === val?.appointments?.appointments?.appointment_type});
      setMydata(filterData);
   };
    
   //  clear appointment filtering
   const ClearAppointmentFilter = () => {
       setFilterid(null);
       setFlage(false);
       setMydata(data);
       setFilter(false);
   };

   // Appointment filtering list 
   const get_app_filter = (value) => {
      const uniqueTypes = new Set();
      for (const obj of value) {
         uniqueTypes?.add(obj?.appointments?.appointments?.appointment_type);
      }
      return Array?.from(uniqueTypes);
   };
   
   //find the pet name
   const FindPetname = (value) => {
      if(Array.isArray(value?.appointments?.appointments)){
         return value?.pet_profiles?.pet_profiles[0]?.name
      }
      const pet_name = value?.pet_profiles?.pet_profiles?.find(item=>item?.vetbuddy_patient_id === value?.appointments?.appointments?.patient_id)?.name
      return pet_name;
   };

   //find the appointment time
   const AppointmentTime = (value) => {
      const app_time = value?.appointments?.appointments?.appointment_time
      if(app_time?.length === 8){
         const app_time_format = app_time?.slice(0,-3)
         return app_time_format
      } else {
        return app_time;
      }
   };

   const membershipStatus = (value) => {
      
      //as discuss with team they send the item in fist array element
      const array_length = value?.membership_details?.membership_packages?.length-1;
      const data = value?.membership_details?.membership_packages[0]?.membership_package;
      var filteredData = data?.filter(item => item.membership_type !== '-');
      const member = filteredData[filteredData?.length-1]?.membership_type?.split(' ')[1];
      return member?.slice(1, -1);
   };

   //vaccination status
   const VaccinationStatus = (value) => {
      const noStatus = value?.vaccination_history?.vaccines.length === 0;
      const Vaccination = value?.vaccination_history?.vaccines;
      const Dog_datafilter = Vaccination?.filter(item => item.pet_type.toLowerCase() === "dog");
      let Dog_data = Dog_datafilter[0]?.details[0]?.vaccinations;
      const Cat_datafilter = Vaccination?.filter(item => item.pet_type.toLowerCase() === "cat");
      let Cat_data = Cat_datafilter[0]?.details[0]?.vaccinations;
      if(Dog_data?.length > 0){
         const hasIsVaccinatedDash = Dog_data?.some(vaccine => vaccine.is_vaccinated === "-");
         return hasIsVaccinatedDash;
      }
      if(Cat_data?.length > 0){
         const hasIsVaccinatedDash = Cat_data?.some(vaccine => vaccine.is_vaccinated === "-");
         return hasIsVaccinatedDash;
      }
      if(noStatus){
         return true
      }
   };
   
   //vaccination expirey
   const VaccinationExpire = (value) => {
      const today = new Date();
      const Vaccination = value?.vaccination_history?.vaccines;
      const Dog_datafilter = Vaccination?.filter(item => item.pet_type.toLowerCase() === "dog");
      let Dog_data = Dog_datafilter[0]?.details[0]?.vaccinations;
      const Cat_datafilter = Vaccination?.filter(item => item.pet_type.toLowerCase() === "cat");
      let Cat_data = Cat_datafilter[0]?.details[0]?.vaccinations;
      if(Dog_data?.length > 0){
         const expired_vaccines = Dog_data?.filter(vaccine => {
            const date = vaccine?.due_on?.split("-")?.reverse()?.join('-');
            const expiry_date = new Date(date);
            return expiry_date < today;
         });
         if(expired_vaccines?.length>0){
            return true
         }
      } 
      if(Cat_data?.length > 0){
         const expired_vaccines = Cat_data?.filter(vaccine => {
            const date=vaccine?.due_on?.split("-")?.reverse()?.join('-');
            const expiry_date = new Date(date);
            return expiry_date < today;
         });
         if(expired_vaccines?.length > 0){
            return true
         }
      } 
   };

   const missingInformatiuon = (value) => {
      return value?.missiong_info !== '-' //missing info
   };

   const handleClickWithEvent = (userId) => {
      const event = {
         "event_type": "CLICK",
         "scr_id": "Home_Page",
         "subaction": "profile_clicked",
         "timestamp": Date.now(),
         "source": "99",
         "member_id": localStorage.getItem("user-email"),
         "user_id": userId.unique_id
      };
      handelEventTrigger(event);
   };   

   const calloutClicked = (userId) => {
      const event = {
         "event_type": "CLICK",
         "scr_id": "List_Page",
         "subaction": "callouts_clicked",
         "timestamp": Date.now(),
         "source": "99",
         "member_id": localStorage.getItem("user-email"),
         "user_id": userId.unique_id
      };
      handelEventTrigger(event);
   }; 
   
   const npsClicked = (userId) => {
      const event = {
         "event_type": "CLICK",
         "scr_id": "List_Page",
         "subaction": "nps_clicked",
         "timestamp": Date.now(),
         "source": "99",
         "member_id": localStorage.getItem("user-email"),
         "user_id": userId.unique_id
      };
      handelEventTrigger(event);
   }; 

   const filterClicked = () => {
      const event = {
         "event_type": "CLICK",
         "scr_id": "List_Page",
         "subaction": "filter_clicked",
         "timestamp": Date.now(),
         "source": "99",
         "member_id": localStorage.getItem("user-email"),
      };
      handelEventTrigger(event);
   }; 

   return (
      <>
         {mydata && 
            <Box className="table-body">
               <Box className="table-heading">
                  <Box className="table-head1"><h4>S.no</h4></Box>
                  <Box className="table-head2"><h4>Name</h4></Box>
                  <Box className="table-head2"><h4>Pet Name</h4></Box>
                  {showAppointment?
                     <Box className="table-head3">
                        <h4 style={{display:"flex"}}>
                           <span className='appintment-drp'> Appointment</span> 
                           <span className='dropdown'> 
                              <span style={{paddingLeft: "2px"}} className='dropbtn' onClick={()=>setFlage(true)}> <HiOutlineFilter className={`${filter?'filterindicate':''}`}/></span>
                              <div className={`${flage? "dropdown-content": "dropdown-contentflage"}`} onClick={() => filterClicked(get_app_filter)}>
                                 {get_app_filter(data)?.map((value, id) => {
                                    return <p key={id} onClick={() => handelAppointment(value, value)} className='appointment-checkbox'> 
                                       <input type='checkbox' defaultChecked={filterid===value} id={value} className={`${value===filterid?'filterindicate':''}`} />
                                       <span htmlFor={id} className='filter-item'>&nbsp;{value}</span>
                                    </p>
                                 })}
                                 <p onClick={ClearAppointmentFilter}>
                                    <input id='siraj' type='checkbox' defaultChecked={filterid===null} />
                                    <span htmlFor='siraj' className='filter-item'>&nbsp;All</span>
                                 </p>
                              </div>
                           </span>
                        </h4>
                     </Box>
                     : <Box className="table-head3"><h4 style={{display:"flex"}}> Client Id</h4></Box>
                  }
                  <Box className="table-head"><h4>H/UH</h4></Box>
                  <Box className="table-head3"><h4>Membership </h4></Box>
               </Box>
               <Box className="table-content">
                  {mydata?.map((value, id) => {
                     return <Box key={id}>
                        <Box className="table-item" onClick={() => handleClickWithEvent(value)}>
                           <Box className="table-head1" onClick={event => handleClick(value,false)}>
                              <p>{(id+1).toString().padStart(2, '0')}</p>
                           </Box>
                           <Box className="table-head2" onClick={event => handleClick(value,false)} >
                              <p style={{display:"flex",gap:"3px",alignItems:"center"}}>
                                 {missingInformatiuon(value?.client_profile) && 
                                    <span style={{display:"flex",height:"1rem"}}>
                                       <img src="https://vetic-img.s3.ap-south-1.amazonaws.com/website/clinics/Danger+Triangle.svg" alt="denger" /> &nbsp;
                                    </span>
                                 }
                                 <span className="wordname"> {value?.client_profile?.name}</span> 
                              </p>
                           </Box>   
                           <Box className="table-head2" onClick={event => handleClick(value,false)}>
                              <p style={{display:"flex"}}>
                                 <span className="petname-in">{FindPetname(value)||"-"} </span>
                                 <span className="vaccination-icon">
                                    {VaccinationStatus(value) ? <MdCancel style={{color:`#F94E63`}} /> : ((VaccinationExpire(value)) ? 
                                       <img src="https://vetic-img.s3.ap-south-1.amazonaws.com/website/clinics/syringe.svg" alt="not vaccinat" style={{color:`#242526`}} /> : 
                                       <MdCheckCircle style={{color:`#199016`}} /> 
                                    )}
                                 </span>
                              </p>
                           </Box>
                           {showAppointment ? 
                              <Box className="table-head3" onClick={event => handleClick(value,false)}>
                                 <p style={{display:"flex", flexDirection:"column"}}>
                                    <span> {value?.appointments?.appointments?.appointment_type}</span> 
                                    <span>{AppointmentTime(value)}</span> 
                                 </p>
                              </Box> : 
                              <Box className="table-head3" onClick={event => handleClick(value,false)}>
                                 <p style={{display:"flex", flexDirection:"column"}}> {value?.client_profile?.vetbuddy_client_id} </p>
                              </Box>
                           }
                           <Box className="table-head" onClick={() => npsClicked(value)}>
                              <p style={{fontSize:"15px"}}>
                                 {value?.client_profile?.feedback?.status?.toLowerCase() === "happy" &&   
                                    <Rca value={value?.client_profile?.feedback?.rca} text="😍" />
                                 }
                              </p>
                              <p style={{fontSize:"15px"}}>
                                 {value?.client_profile?.feedback?.status?.toLowerCase() === "unhappy" && 
                                    <Rca value={value?.client_profile?.feedback?.rca} text="😡" />
                                 }
                              </p>
                              <p style={{fontSize:"15px"}}>
                                 {value?.client_profile?.feedback?.status?.toLowerCase() === "neutral" && 
                                    <Rca value={value?.client_profile?.feedback?.rca}text="😑" />
                                 }
                              </p>
                              <p style={{fontSize:"15px",paddingLeft:"5px"}}>
                                 {(value?.client_profile?.feedback?.status === "" || value?.client_profile?.feedback?.status === "-") && "-"}
                              </p>
                           </Box>
                           <Box className="table-head3">
                              <p> 
                                 {value?.membership_details?.membership_packages?.length === 0 && 
                                    <span className="notmember" onClick={event => handleClick(value,false)}>Not Member </span>
                                 }
                                 {value?.membership_details?.membership_packages?.length > 0 && 
                                    <span onClick={event => handleClick(value, false)}> Member {membershipStatus(value)}</span>
                                 }
                                 <span style={{paddingLeft:"3.2vw"}}></span>
                                 <span style={{fontSize:"18px" }} onClick={() => calloutClicked(value)}>
                                    {value?.client_callouts?.callouts?.length > 0 && <CallOut feedback_value={value} />}    
                                 </span>
                              </p>
                           </Box>
                        </Box> 
                     </Box>
                  })}
               </Box>
            </Box> 
         }
      </>  
   );
};

export default Userlist;