import React from 'react';
import Box from '@mui/material/Box'; 

const Membership = ({ data }) => {
   const Membership=data?.membership_details?.membership_packages;
   const TotalVisti=data?.visits?.visits;
   const total_count=data?.client_profile?.no_of_visits;
  
   return (
      <>
         <Box className="user-info-box">
            <Box className="user-info-left">
               <h5 className='user-name-info'>Utilization</h5>        
               {Membership?.map((value, id) => {
                  return <Box key={id}>
                     <p className='para-item' style={{paddingLeft:"5px"}}>{value?.membership_name}</p> 
                     <ul style={{marginTop:"0"}}>
                        {value?.membership_package.map((item, id) => {
                           return <li key={id} className='para-item'>{item?.membership_type}</li> 
                        })}
                     </ul>
                  </Box> 
               })}                 
            </Box>
            <Box className="user-info-right-me">
               <h5 className='user-name-info'>Total Visits:<span> {total_count||"0"}</span></h5>
               <ul style={{marginTop:"0"}}>
                  {TotalVisti?.map((value, id) => {
                     return <li key={id} className='para-item' style={{textTransform: "capitalize"}}>{value?.type}: 
                        <span> {value?.count?.toString().padStart(2, '0')}</span>
                     </li>
                  })}
               </ul>
            </Box>
         </Box>
      </>
   );
};

export default Membership;