import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const StockIncompleteForm = ({Client_name, Client_id, Client_mobile, unique_id}) => {

    const handelEventTrigger = (value) => {
        const headers = {
           'Content-Type': 'application/json'
        };
        axios.post("https://webhooks.vetic.in/retail-app/click-logs/", value, { headers })
        .then(response => console.log(response.data))
        .catch(error => console.log('error', error));
      };
    
      const clickEvent = () => {
        const event = {
           "event_type": "CLICK",
           "scr_id": "Profile_Page",
           "subaction": "stock_form_clicked",
           "timestamp": Date.now(),
           "source": "99",
           "member_id": localStorage.getItem("user-email"),
           "user_id": unique_id,
        };
        handelEventTrigger(event);
     }; 

    return (
       <Box className='stock_main_container'>
            <Box className='stock_text_main'>
                <p className='stock_text'>Submit stock incomplete form:</p>
            </Box>
            <Box className='stock_button_main'>
                <Button className="stock_button" onClick={() => clickEvent()} component={Link} to={`/stock-incomplete-form?name_of_requester=${localStorage.getItem('user-email')}&client_name=${encodeURIComponent(Client_name)}&client_mobile=${encodeURIComponent(Client_mobile)}&client_id=${Client_id}&unique_id=${unique_id}`} target="_blank" rel="noopener noreferrer">
                    Click Here
                </Button>
            </Box>
       </Box> 
    );
};

export default StockIncompleteForm; 