/* eslint-disable no-restricted-globals */
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: 'AIzaSyDBmaf5B8Ii9wiPUXWM6l9fpG1iJAOhePU',
  appId: '1:741842529276:web:40e30914419e967d6ce079',
  messagingSenderId: '741842529276',
  projectId: 'vetic-bcf18',
  authDomain: 'vetic-bcf18.firebaseapp.com',
  storageBucket: 'vetic-bcf18.appspot.com',
  measurementId: 'G-YT559YBW66'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const  handelEventTrigger = (value) => {
  const headers = {
    'Content-Type': 'application/json'
  };
  axios.post("https://webhooks.vetic.in/retail-app/click-logs/", value, { headers })
    .then(response => console.log(response.data))
    .catch(error => console.log('error', error));
};

const HandelFuntion = ( email, userAuthInfo) => {
  let jsonString = localStorage.getItem('authdata');
  // Parse JSON string back into an array
  let myData = JSON.parse(jsonString);
  const myEmailId=email
  const myObject = myData?.find(obj => obj.email === myEmailId);
  localStorage.setItem('vet_id',myObject.vetbuddy_instance_id );
  localStorage.setItem('vet_clinic_id',myObject.vetbuddy_clinic_id );
  localStorage.setItem('clinic_name',myObject.vetbuddy_clinic_name );
  localStorage.setItem('state',myObject?.email===myEmailId );
  localStorage.setItem('permit',myObject?.permit );
  localStorage.setItem('dist_id',myObject?.distric_id );
  localStorage.setItem('error',false );
  localStorage.setItem("user-email",userAuthInfo?.displayName)
  localStorage.setItem("user-email-address",myEmailId)
  location?.reload(); //to use flage or manupulation the screen

  // Get the current date and time
  const currentDate = new Date();

  // Get the login time as the current date with the hours and minutes from the login time
  const loginTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), userAuthInfo.loginHour, userAuthInfo.loginMinute);

  // Calculate the logout time after 10 hours from the login time
  const logoutTime = new Date(loginTime.getTime() + 10 * 60 * 60 * 1000);

  // Calculate 10 PM time for the login date
  const tenPM = new Date(loginTime.getFullYear(), loginTime.getMonth(), loginTime.getDate(), 22, 0, 0, 0);

  // Set the timeout to automatically log out after 10 hours from login time or at 10 PM on the login date, whichever is shorter
  const logoutDelay = Math.min(logoutTime.getTime() - loginTime.getTime(), tenPM.getTime() - loginTime.getTime());
  setTimeout(() => {
    signOut(auth)
      .then(() => {
        console.log("User logged out automatically.");
      })
      .catch((error) => {
        console.log("Error logging out user:", error);
      });
  }, logoutDelay);
};

const accessNotvalid = () => {
  localStorage.setItem('error',true );
  location.reload()
};

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
  .then((result) => {  
    if (auth.currentUser.email.split("@")[1] === "vetic.in") {
      const userAuthInfo = {
        displayName: auth.currentUser.displayName,
        loginHour: new Date().getHours(),
        loginMinute: new Date().getMinutes(),
      };
      HandelFuntion(auth.currentUser.email, userAuthInfo) 
      const dataLog = {
        "event_type": "SUCCESS",
        "scr_id": "Signup_Screen",
        "timestamp":Date.now() ,
        "source":"99",
        "member_id": auth.currentUser.email
      };
      handelEventTrigger(dataLog);  
    } else {
      accessNotvalid()  
    }
  })
  .catch((error) => {
    console.log(error);
  });
};

export { auth, provider};